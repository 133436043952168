<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'120px'">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="首页图" prop="imgUrl">
          <el-upload
            class="avatar-uploader"
            name="photoFile"
            :action="uploadUrl"
            :show-file-list="false"
            :headers="headers"
            :data="uploadData"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="fileUrl" :src="fileUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="审核状态" prop="enabled">
          <el-radio-group v-model="formModel.enabled">
            <el-radio :label="true">已审核</el-radio>
            <el-radio :label="false">未审核</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNo">
          <el-input-number v-model="formModel.sortNo" placeholder="请输入排序号" style="width:200px"></el-input-number>
        </el-form-item>
        <el-form-item label="类型" prop="linkType">
          <el-radio-group v-model="formModel.linkType">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转链接地址" prop="linkUrl">
          <el-input v-model="formModel.linkUrl" placeholder="请输入跳转链接地址" style="width:100%"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import newsTypeApi from "@/api/base/newsType";
import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        name: [{ required: true, message: "不能为空", trigger: "blur" }],
        imgUrl: [{ required: true, message: "请上传", trigger: "blur" }],
        enabled: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        sortNo: [{ required: true, message: "不能为空", trigger: "blur" }],
        linkType: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      fileUrl: "",
      //上传地址
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "newsType",
      },
      headers: {
        Authorization: getToken(),
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return newsTypeApi.add(self.formModel);
            } else {
              return newsTypeApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleSuccess(res, file) {
      var self = this;
      self.formModel.imgUrl = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片只能是 JPG/GIF/PNG/BMP 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      //return isJPG && isLt2M;
      return isJPG || isGIF || isPNG || isBMP;
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return newsTypeApi.create();
      } else {
        return newsTypeApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          let imgUrl = self.formModel.imgUrl;
          if (imgUrl != null) {
            self.fileUrl =
              imgUrl + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
          }

          if (self.formModel.linkUrl == null) {
            self.formModel.linkUrl = "#";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>