<template>
  <el-dialog
    :visible.sync="showDialog"
    title="新闻类型管理"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="70%"
  >
    <div>
      <el-divider></el-divider>
      <el-row class="button-group">
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-circle-plus"
          @click="handleAdd"
        >新增</el-button>
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-circle-plus"
          :disabled="multipleSelection.length==0"
          @click="handleBatchDelete"
        >删除选中项</el-button>
        <el-button
          type="primary"
          size="small"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >刷新</el-button>
      </el-row>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="sortNo" sort-by="sort_no" label="排序号" width="80"></el-table-column>
        <el-table-column prop="name" sort-by="name_" label="名称" width="150"></el-table-column>
        <el-table-column prop="imgUrl" sort-by="img_url" label="首页图片路径" width="180">
          <template slot-scope="{row}">
            <a v-if="row.imgUrl != null" :href="row.imgUrl" target="_blank">
              <img
                :src="row.imgUrl+'?x-oss-process=image/resize,m_lfit,w_40'"
                :key="row.id"
                style="width:180px,fit-object:cover;"
              />
            </a>
          </template>
        </el-table-column>
        <el-table-column prop="enabled" sort-by="enabled_" label="审核状态" width="80">
          <template slot-scope="{row}">
            <el-switch
              v-model="row.enabled"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="updateSwitch(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="linkType" sort-by="link_type" label="类型" width="80">
          <template slot-scope="{row}">
            <span v-if="row.linkType == '0'" style="color:green">正常</span>
            <span v-else-if="row.linkType == '1'" style="color:red">跳转</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="linkUrl"
          sort-by="link_url"
          label="跳转链接地址"
          width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>-
            <el-link type="danger" :underline="false" @click="handleDelete(row)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <newsType-detail
        v-if="showModal"
        :businessKey="businessKey"
        :title="modalTitle"
        @close="onDetailModalClose"
      ></newsType-detail>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import NewsTypeDetail from "./newsType-detail";
import newsTypeApi from "@/api/base/newsType";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        delFlag: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        name: "",
        imgUrl: "",
        enabled: "",
        sortNo: "",
        linkType: "",
        linkUrl: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      showDialog: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("createBy", self.queryModel.createBy);
      formData.append("createTime", self.queryModel.createTime);
      formData.append("updateBy", self.queryModel.updateBy);
      formData.append("updateTime", self.queryModel.updateTime);
      formData.append("name", self.queryModel.name);
      formData.append("imgUrl", self.queryModel.imgUrl);
      formData.append("enabled", self.queryModel.enabled);
      formData.append("sortNo", self.queryModel.sortNo);
      formData.append("linkType", self.queryModel.linkType);
      formData.append("linkUrl", self.queryModel.linkUrl);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      newsTypeApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          newsTypeApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        newsTypeApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    updateSwitch(row) {
      newsTypeApi.update(row);
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "newsType-detail": NewsTypeDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>