
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    :width="'800px'"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
        :inline="true"
      >
        <el-row>
          <el-form-item label="新闻类型" prop="typeId">
            <el-select v-model="formModel.typeId" filterable placeholder="请选择" style="width:280px">
              <el-option
                v-for="type in newsInfoList"
                :key="type.id"
                :label="type.name"
                :value="type.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="标题" prop="title">
            <el-input v-model="formModel.title" placeholder="请输入标题" style="width:300px"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="内容" prop="content">
            <mce-editor
              ref="editor"
              uploadName="upfile"
              v-model="formModel.content"
              :url="uploadUrl"
              :config="editorConfig"
            ></mce-editor>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="状态2" prop="status">
            <el-radio-group v-model="formModel.status">
              <el-radio :label="true">已审核</el-radio>
              <el-radio :label="false">未审核</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="领取积分" prop="isReceive">
            <el-radio-group v-model="formModel.isReceive">
              <el-radio :label="true">领取</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="积分" prop="point">
            <el-input v-model="formModel.point" placeholder="请输入分数" style="width:100px"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="需要签字" prop="isSign">
            <el-radio-group v-model="formModel.isSign">
              <el-radio :label="true">签字</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import newsInfoApi from "@/api/base/newsInfo";
import newsTypeApi from "@/api/base/newsType";
import MceEditor from "@/components/Tinymce";
import { getToken } from "@/utils/auth";

export default {
  props: ["businessKey", "title"],
  components: {
    "mce-editor": MceEditor,
  },
  data() {
    return {
      formModel: {},
      ruleValidate: {
        title: [{ required: true, message: "不能为空", trigger: "blur" }],
        status: [{ required: true, message: "请选择", trigger: "blur" }],
        isSign: [{ required: true, message: "请选择", trigger: "blur" }],
        isReceive: [{ required: true, message: "请选择", trigger: "blur" }],
        typeId: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      editorConfig: {
        height: 500,
        width: 600,
      },
      uploadUrl: Constant.serverUrl + "/tinymce/upload?token=" + getToken(),
      newsInfoList: [],
    };
  },
  created() {
    var self = this;

    newsTypeApi.newsTypeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.newsInfoList = jsonData.data;
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.content = self.$refs.editor.getContent();
            if (id == null || id.length == 0) {
              return newsInfoApi.add(self.formModel);
            } else {
              return newsInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return newsInfoApi.create();
      } else {
        return newsInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if (self.formModel.content != null) {
            self.$refs.editor.setContent(self.formModel.content);
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>