<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/newsInfo">新闻管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="新闻类型" prop="typeId">
        <el-select
          v-model="queryModel.typeId"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:180px"
        >
          <el-option
            v-for="type in newsInfoList"
            :key="type.id"
            :label="type.name"
            :value="type.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input type="text" size="mini" v-model="queryModel.title"></el-input>
      </el-form-item>
      <el-form-item label="审批状态" prop="status">
        <el-select
          v-model="queryModel.status"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:100px"
        >
          <el-option label="全部" value></el-option>
          <el-option label="已审核" value="1"></el-option>
          <el-option label="未审核" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleOpenTypeList"
      >新闻类型管理</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="typeName" sort-by="type_id" label="类型" width="180"></el-table-column>
      <el-table-column prop="title" sort-by="title_" label="标题" width="250" show-overflow-tooltip></el-table-column>
      <el-table-column prop="status" sort-by="status_" label="审核状态" width="120">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateSwitch(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="isReceive" sort-by="is_receive" label="领取积分" width="120">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.isReceive"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateSwitch(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="point" sort-by="point_" label="积分" width="80"></el-table-column>
      <el-table-column prop="isSign" sort-by="is_sign" label="需要签字" width="120">
        <template slot-scope="{row}">
          <el-switch
            v-model="row.isSign"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateSwitch(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" sort-by="create_time" label="创建时间" width="150"></el-table-column>
      <el-table-column prop="updateTime" sort-by="update_time" label="修改时间" width="150"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <newsInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></newsInfo-detail>
    <newsType-list
      v-if="showTypeModal"
      @close="onDetailModalClose"
    ></newsType-list>
  </div>
</template>
<script>
import Constant from "@/constant";
import NewsInfoDetail from "./newsInfo-detail";
import NewsTypeList from "./newsType-list";
import newsInfoApi from "@/api/base/newsInfo";
import newsTypeApi from "@/api/base/newsType";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        title: "",
        typeId: "",
        status: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      newsInfoList: [],
      showTypeModal: false,
    };
  },
  created() {
    var self = this;

    newsTypeApi.newsTypeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.newsInfoList = jsonData.data;
      }
    });
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("title", self.queryModel.title);
      formData.append("typeId", self.queryModel.typeId);
      formData.append("status", self.queryModel.status);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      newsInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          newsInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        newsInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showTypeModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    updateSwitch(row) {
      newsInfoApi.update(row);
    },
    handleOpenTypeList() {
      this.showTypeModal = true;
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "newsInfo-detail": NewsInfoDetail,
    "newsType-list":NewsTypeList
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>